import color from '@/styles/color';
import { CryptoLogoProps } from '@web3uikit/core';

/**
 * @deprecated
 */
export const getCryptoLogoProps = (chain?: string): CryptoLogoProps => {
  // TODO: (Maxim) remove this when we have a proper chain name type
  // const chain = chainArg?.toLocaleLowerCase();
  // if (!isChainName(chain)) {
  //   assert(chain as never);
  //   return { chain: 'ethereum', bgColor: color.moneyBlue50 };
  // }
  switch (chain) {
    case 'polygon':
      return { chain: 'polygon', bgColor: color.purple50 };
    case 'arbitrum':
      return { chain: 'arbitrum', bgColor: color.blue40 };
    case 'fantom':
      return { chain: 'fantom', bgColor: color.moneyBlueNatural40 };
    case 'avalanche':
      return { chain: 'avalanche', bgColor: '#DE0000' };
    case 'bsc':
    case 'binance':
      return { chain: 'binance', bgColor: color.yellow50 };
    case 'coinbase':
      return { chain: 'coinbase', bgColor: color.blueGray50 };
    case 'ronin':
      return { chain: 'ronin', bgColor: color.red60 };
    case 'optimism':
      return { chain: 'optimism', bgColor: color.red30 };
    case 'ethereum':
      return { chain: 'ethereum', bgColor: color.moneyBlue50 };
    case 'pulse':
    case 'pulsechain':
      return {
        chain: 'pulsechain',
        bgColor:
          'linear-gradient(211.94deg, rgb(0, 234, 255) 14.03%, rgb(0, 128, 255) 32.21%, rgb(128, 0, 255) 50.38%, rgb(230, 25, 230) 68.56%, rgb(255, 0, 0) 86.74%), rgb(255, 255, 255)',
      };
    case 'base':
      return { chain: 'base', bgColor: '#0052FF' } as unknown as CryptoLogoProps; // @note - old web3uikit types are being used here and this needs to be removed or moved to MUI
    case 'linea':
      return { chain: 'linea', bgColor: '#121212' } as unknown as CryptoLogoProps; // @note - old web3uikit types are being used here and this needs to be removed or moved to MUI
    case 'solana':
      return { chain: 'solana', bgColor: '#7870C6' } as unknown as CryptoLogoProps; // @note - old web3uikit types are being used here and this needs to be removed or moved to MUI
    default:
      return { chain: 'ethereum', bgColor: color.moneyBlue50 };
  }
};
