import React, { ErrorInfo, ReactNode } from 'react';
import { logApp } from '@/utils/logApp';
import { toError } from '@/utils/toError';

interface Props {
  children?: ReactNode;
  renderFallback: (error: Error) => ReactNode;
}

type State =
  | {
      error: Error;
    }
  | {
      error: null;
    };

const log = logApp.create('ErrorBoundary');

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: unknown) {
    return { error: toError(error) };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.error('[componentDidCatch]', error, errorInfo);
  }

  public render() {
    return this.state.error ? this.props.renderFallback(this.state.error) : this.props.children;
  }
}
