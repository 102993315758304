import useSWR, { Arguments, SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { useAuthStore } from '@/stores/authStore';

type StaticConfig<T, Arg> = SWRConfiguration<T> & {
  immutable?: boolean;
  key?: Arguments | ((arg: Arg) => Arguments);
};

type InstanceConfig<T> = { disable?: boolean; fallbackData?: SWRConfiguration<T>['fallbackData'] };

export type SWRFetcher<T, Arg> = (arg: Arg) => null | Promise<T>;

const getStatic = <T, Arg>(fn: SWRFetcher<T, Arg>, staticCfg: StaticConfig<T, Arg>) => {
  const { key = fn.name || String(fn) } = staticCfg;
  const keyFn = typeof key !== 'function' ? () => key : (key as (arg: Arg) => Arguments);
  const useMySWR = staticCfg.immutable ? useSWRImmutable : useSWR;
  return {
    useMySWR,
    getKey: (arg: Arg | null) => {
      const keyVal = arg === null ? null : keyFn(arg);
      return keyVal == null ? null : [keyVal, arg];
    },
    getConfig: (cfg: InstanceConfig<T>) => {
      if (!cfg.fallbackData) {
        cfg.fallbackData = staticCfg.fallbackData;
      }
      return cfg;
    },
    toFnByPromise: (fnVal: Promise<T> | null) => (fnVal == null ? null : () => fnVal),
  };
};

/**
 * Simple wrapper over useSWR and useSWRImmutable
 * @usage
 * ```tsx
 * const useFoo = swrHook.create(async (abc: string) => Number(abc));
 * // or
 * const useFoo = swrHook.create(async (abc: string) => Number(abc), {immutable: true, revalidateOnFocus: false});
 * // =>
 * useFoo('123').data; // number | undefined
 * useFoo('123', {disable: true}).data; // Funcrtion is not called => undefined
 * ```
 * You can control enable/disable by passing `disable` prop or return null instead of the Promise:
 * ```tsx
 * const fetchMe = apiFetcher.oneInch('/v6.0/{chain}/approve/allowance', 'GET');
 * const useFoo = swrHook.create(
 *   ({ tokenAddress, walletAddress, chain }: { tokenAddress?: string; walletAddress?: number; chain: string }) =>
 *     isString(tokenAddress) && isNumber(walletAddress)
 *       ? fetchMe({
 *           pathParams: {
 *             chain,
 *           },
 *           queryParams: {
 *             tokenAddress,
 *             walletAddress: String(walletAddress),
 *           },
 *         })
 *       : null,
 * );
 * const Comp = ({ tokenAddress, walletAddress }: { tokenAddress?: string; walletAddress?: number }) => {
 *   const { data } = useFoo({
 *     tokenAddress,
 *     walletAddress,
 *     chain: 'ethereum',
 *   });
 * };
 * ```
 */
export const swrHook = {
  create: <T, Arg>(fn: SWRFetcher<T, Arg>, staticCfg: StaticConfig<T, Arg> = {}) => {
    const { useMySWR, getKey, getConfig, toFnByPromise } = getStatic(fn, staticCfg);
    return (arg: Arg | null, cfg: InstanceConfig<T> = {}) =>
      useMySWR(getKey(arg), toFnByPromise(fn(arg as Arg)), getConfig(cfg));
  },

  createWithAuth: <T, Arg extends { authToken: string | undefined }>(
    fn: SWRFetcher<T, Arg>,
    staticCfg: StaticConfig<T, Arg> = {},
  ) => {
    const { useMySWR, getKey, getConfig, toFnByPromise } = getStatic(fn, staticCfg);
    return (
      // This trick is to allow to NOT pass authToken as it is already provided internally
      argSrc: (Omit<Arg, 'authToken'> & { authToken?: string | undefined }) | null,
      cfg: InstanceConfig<T> = {},
    ) => {
      const arg = argSrc as Arg;
      if (arg !== null && arg.authToken === undefined) {
        arg.authToken = useAuthStore.getState().authToken;
      }
      return useMySWR(getKey(arg), toFnByPromise(fn(arg)), getConfig(cfg));
    };
  },
};
