import { isString } from '../isType';
import { ALL_FEATURE_FLAGS, FeatName } from './ALL_FEATURE_FLAGS';
import { log } from './log';

export const parseFeatureValueSafe = <K extends FeatName>(featureName: K, value: unknown) => {
  const cfg = ALL_FEATURE_FLAGS[featureName];
  if (!isString(value)) {
    return cfg.default;
  }
  try {
    return cfg.parse(value);
  } catch (error) {
    log.warnError('Failed to validate feature flag', error, { featureName, value });
  }
  return cfg.default;
};
