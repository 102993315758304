import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useAuthToken } from '@/helpers/hooks/useAuth';
import { apiBrowser } from '@/utils/api/apiBrowser';
import { logApp } from '@/utils/logApp';
import { addWalletToPortfolio } from '../libs/addWallet';
import { EAdditionMethod } from '../libs/types';
import { useMultiWalletData } from './useMultiWalletData';

const log = logApp.create('useAddConnectedWallet');
const fetchUserPortfolioWallets = apiBrowser.user('/user/portfolioWallets', 'GET');

/**
 * Checks if a connected wallet is already stored in the user lists. If not adds it.
 */
export const useAddConnectedWallet = () => {
  const { requireAuthToken, decodedAuthToken } = useAuthToken();
  const { address, connector } = useAccount();
  const { mutate } = useMultiWalletData();
  const authAddr = decodedAuthToken?.address.toLowerCase();

  useEffect(() => {
    if (!authAddr || !address) {
      return;
    }

    // If the connected account is the same as the one linked to the user money profile, return
    if (authAddr === address.toLowerCase()) {
      return;
    }

    const connectorName = connector?.name || EAdditionMethod.WATCHED;

    fetchUserPortfolioWallets({
      authToken: requireAuthToken(),
    }).then((res) => {
      // Check if the address is already included in a list
      const found = res.wallets.find((wallet) => wallet.address.toLowerCase() === address.toLowerCase());

      if (found) {
        return;
      }

      // Add the wallet to the portfolio list
      addWalletToPortfolio(address, connectorName as EAdditionMethod)
        .then(() => mutate())
        .catch((err) => {
          log.warnError('[addWalletToPortfolio]', err, { address });
        });
    });
  }, [authAddr, address]);
};
