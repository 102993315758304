import { useCallback, useMemo } from 'react';
import { useAuthStore } from '@/stores/authStore';
import { invariant } from '@/utils/invariant';
import { parseMoralisToken } from '@/utils/parseMoralisToken';

export const useAuthToken = () => {
  const normalToken = useAuthStore((s) => s.authToken);
  const requireEmailToken = useAuthStore((s) => s.requireEmailToken);
  const authMethod = useAuthStore((s) => s.authMethod);
  const authToken = requireEmailToken ?? normalToken;
  return {
    authToken,
    authMethod,
    isLoggedIn: Boolean(authToken),
    decodedAuthToken: useMemo(() => (authToken ? parseMoralisToken(authToken) : undefined), [authToken]),

    requireAuthToken: useCallback(() => {
      invariant(authToken, 'Auth token is required');
      return authToken;
    }, [authToken]),
  };
};

export const useAuthClear = () => useAuthStore((s) => s.clearAuth);
export const useAuthCustomer = () => ({
  customer: useAuthStore((s) => s.customer),
  setCustomer: useAuthStore.getState().setCustomer,
});
