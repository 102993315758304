import { asString, asStringEnum } from '../asType';

const asNodeEnv = asStringEnum({
  development: true,
  production: true,
  test: true,
});

const asEnvironment = asStringEnum({
  local: true,
  prod: true,
  dev: true,
});

const asVerbosity = asStringEnum({
  quiet: true,
  verbose: true,
  normal: true,
});

export const getEnvApp = () => {
  const nodeEnv = asNodeEnv(process.env.NODE_ENV ?? 'development', 'NODE_ENV');
  const environment = asEnvironment(process.env.RUNTIME_ENV ?? 'local', 'RUNTIME_ENV');
  const isTest = nodeEnv === 'test';
  const isProd = environment === 'prod';
  const defaultVerbosity = isProd ? 'normal' : isTest ? 'quiet' : 'verbose';

  return {
    logName: 'moralis.com',
    version: asString(process.env.NEXT_PUBLIC_APP_VERSION ?? 'dev', 'NEXT_PUBLIC_APP_VERSION'),
    nodeEnv,
    environment: asEnvironment(process.env.RUNTIME_ENV ?? 'local', 'RUNTIME_ENV'),
    // app config
    useAnalytics: isProd,
    verbosity: asVerbosity(process.env.VERBOSITY ?? defaultVerbosity, 'VERBOSITY'),
    usePrettyLog: Boolean(process.env.PRETTY_LOG),
  };
};
