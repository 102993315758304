import { useMemo } from 'react';
import useSWR from 'swr';
import { GetTokenExplorerResponseDto, PostTokenExplorerRequestDto, PutQueryRequestDto } from '@/services/index';
import { api } from '@/utils/api';
import { useAuthToken } from '../useAuth';
import { language } from './values';

const fetchStrategies = async () => {
  return await api.user.userControllerGetStrategy1();
};

export const useStrategies = () => {
  const { isLoggedIn } = useAuthToken();
  const { data, error, mutate, isLoading, isValidating } = useSWR(
    isLoggedIn ? 'get-strategies' : null,
    fetchStrategies,
  );

  const updateStrategy = async (requestBody: PutQueryRequestDto) => {
    const updatedStrategy = await api.user.userControllerUpdateStrategyAlert1(requestBody);
    mutate(
      (prevData) =>
        prevData?.map((oldStrategy) => (oldStrategy.id === updatedStrategy.id ? updatedStrategy : oldStrategy)),
      false,
    );
    return updatedStrategy;
  };

  const manageStrategyStatus = async (requestBody: PutQueryRequestDto, isPublic: boolean) => {
    const modifiedRequest = {
      ...requestBody,
      isPublic: isPublic,
    };
    const newStrategy = await api.user.userControllerUpdateStrategyAlert1(modifiedRequest);
    mutate(
      (prevData) => prevData?.map((oldStrategy) => (oldStrategy.id === newStrategy.id ? newStrategy : oldStrategy)),
      false,
    );
    return newStrategy;
  };

  const createStrategy = async (requestBody: PostTokenExplorerRequestDto) => {
    const newStrategy = (await api.user.userControllerCreateStrategyAlert1(requestBody)) as GetTokenExplorerResponseDto;
    mutate((prevData) => [...(prevData || []), newStrategy], false);
    return newStrategy;
  };

  const deleteStrategy = async (strategyId: string) => {
    await api.user.userControllerDeleteStrategyAlert1(strategyId);
    mutate((prevData) => prevData?.filter((strategy) => strategy.id !== strategyId), false);
  };

  const toggleStrategy = async (strategyId: string, enabled: boolean) => {
    let updatedStrategy: GetTokenExplorerResponseDto;
    if (enabled) {
      updatedStrategy = await api.user.userControllerEnableStrategyAlert({
        strategyId,
      });
    } else {
      updatedStrategy = await api.user.userControllerDisableStrategyAlert({
        strategyId,
      });
    }

    mutate(
      (prevData) =>
        prevData?.map((oldStrategy) => (oldStrategy.id === updatedStrategy.id ? updatedStrategy : oldStrategy)),
      false,
    );

    return updatedStrategy;
  };

  const deleteAlertFromStrategy = async (strategyId: string) => {
    const targetStrategy = data?.find((strategy) => strategy.id === strategyId);
    if (!targetStrategy) {
      throw new Error(`Strategy with id ${strategyId} not found`);
    }
    return await updateStrategy({
      ...targetStrategy,
      notificationData: [],
      notification: false,
      monitorTimeInMinutes: undefined,
    });
  };

  const strategies = useMemo(() => {
    if (!data) return;
    const withAlert: GetTokenExplorerResponseDto[] = [];
    const noAlert: GetTokenExplorerResponseDto[] = [];

    for (const strategy of data) {
      if (strategy.notificationData?.length) {
        withAlert.push(strategy);
      } else {
        noAlert.push(strategy);
      }
    }

    return { withAlert, noAlert, all: data };
  }, [data]);

  return {
    messages: language.en,
    strategies,
    isLoading: isLoading || isValidating,
    error,
    createStrategy,
    updateStrategy,
    manageStrategyStatus,
    deleteStrategy,
    toggleStrategy,
    deleteAlertFromStrategy,
  };
};
