import { AppProps } from 'next/app';
import { BeforeInteractiveScripts } from '@/components/BeforeInteractiveScripts';
import { ErrorBoundary } from '@/components/error/ErrorBoundary';
import { ErrorFeedback } from '@/components/error/ErrorFeedback';
import { Providers } from '@/components/Providers';
import '@rainbow-me/rainbowkit/styles.css';
import '@/wordpress/styles/post/styles.css';
import '@/styles/scss/global.scss';
import '@/styles/styles.css';

const Dapp = ({ Component, pageProps }: AppProps) => {
  return (
    <ErrorBoundary renderFallback={(err) => <ErrorFeedback text={err.message} />}>
      <Providers messages={pageProps.translations}>
        <Component {...pageProps} />
      </Providers>
      <BeforeInteractiveScripts />
    </ErrorBoundary>
  );
};

export default Dapp;
