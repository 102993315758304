import { useWatch } from 'react-hook-form';
import useUserStore from '@/stores/userStore';
import { EProtocolType } from '@/types/validatedProtocol';
import { RX_EMAIL } from '@/utils/regex';
import { ToggleableInput } from '../../atoms';
import { language } from './values';
import commonStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const NotificationsFieldset = () => {
  const allowedProtocols: Record<EProtocolType, boolean> | undefined = useUserStore(
    (state) => state?.stage?.allowedAlertProtocols,
  );
  const protocolToggles: boolean[] = useWatch({
    name: ['toggleProtocolEmail', 'toggleProtocolTelegram', 'toggleProtocolWebhook'],
  });

  const isAtLeastOneChecked = protocolToggles.some(Boolean);
  return (
    <div>
      <div className={commonStyles.col25}>
        <h5 className={typography.bodySmallRegular} style={{ lineHeight: '35px' }}>
          {language.en.title}
        </h5>
      </div>

      <div className={commonStyles.col75}>
        <div className={styles.content}>
          <ToggleableInput
            disabled={allowedProtocols?.email === false}
            inputName="inputProtocolEmail"
            label="Email"
            placeholder={language.en.emailPlaceholder}
            required={!isAtLeastOneChecked}
            toggleName="toggleProtocolEmail"
            validate={(value) => RX_EMAIL.test(value)}
          />
          <ToggleableInput
            disabled={allowedProtocols?.telegram === false}
            inputName="inputProtocolTelegram"
            label="Telegram"
            placeholder={language.en.telegramPlaceholder}
            toggleName="toggleProtocolTelegram"
          />
          <ToggleableInput
            disabled={!allowedProtocols?.webhook}
            inputName="inputProtocolWebhook"
            label="Webhook"
            placeholder={language.en.webhookPlaceholder}
            toggleName="toggleProtocolWebhook"
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationsFieldset;
