'use client';
import { API } from '@/api';
import { fetchJSON } from '../fetchJSON';

// TODO: (Maxim) simpify proxy types
type ProxiedResp = API.Insights.Paths['/hiddenGems/listCoins']['post'];
type ReqBody = API.Utils.Body<ProxiedResp>;
type Resp = API.Utils.Response<ProxiedResp>;
export const fetchHiddenGemsCoins = (body: ReqBody) =>
  fetchJSON({
    url: '/api/insights/list-coins',
    response: (resp): resp is Resp => Array.isArray(resp),
    body,
  });
