import { invariant } from '../invariant';
import { getEnvApp } from './getEnvApp';

declare global {
  interface Window {
    moralis: ReturnType<typeof getTheMoralisOnServer>;
  }
}

const getTheMoralisInBrowser = () => {
  invariant(window, 'should be called client-side only');
  invariant(window.moralis, 'moralis is not defined');
  return window.moralis;
};

const isBrowser = typeof window !== 'undefined';
const getTheMoralisOnServer = () => {
  invariant(!isBrowser, 'should be called server-side only');
  return {
    debug: {} as Record<string, unknown>,
    EnvApp: getEnvApp(),
  };
};

/**
 * this is used to pass config from server runtime to browser's window
 * See pages/_document.tsx and vitest.setup.ts
 * Use with caution!
 */
export const theMoralis: Window['moralis'] = !isBrowser ? getTheMoralisOnServer() : getTheMoralisInBrowser();
