'use client';
import Script from 'next/script';
import { isDevModeEnabled } from '@/utils/devMode';
import { EnvApp } from '@/utils/env/app';

export const CookieScript = () => {
  const isIframe = typeof window !== 'undefined' && window.self !== window.top;
  if (isIframe || !EnvApp.useAnalytics || isDevModeEnabled()) {
    return null;
  }

  return <Script src="//cdn.cookie-script.com/s/816d8f567d2e5375195076e98af215e3.js" type="text/javascript" async />;
};
