import { FC, useRef, useState } from 'react';
import color from '@/styles/color';
import { getShieldColor } from '@/utils/safetyShieldColors';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  size,
  useDismiss,
  useFloating,
  useHover,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { faShield } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpgradeProLink } from '../UpgradeProLink';
import { language } from './values';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

interface ISafetyShieldProps {
  dexLevel: number;
  fontSize?: number;
  isDisabled?: boolean;
  shieldSize?: number;
}

const SafetyShield: FC<ISafetyShieldProps> = ({ dexLevel, fontSize = 32, shieldSize = 30, isDisabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    placement: 'top',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        crossAxis: -6,
        mainAxis: 6,
      }),
      flip({
        fallbackAxisSideDirection: 'end',
      }),
      shift({
        padding: 6,
      }),
      arrow({
        element: arrowRef,
      }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `calc(${availableWidth}px - 6px)`,
            maxHeight: `calc(${availableHeight}px - 6px)`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    handleClose: safePolygon(),
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss, role]);

  const headingId = useId();

  return (
    <>
      <div
        className={`${styles.shield} ${dexLevel === 0 ? 'na' : ''}`}
        ref={refs.setReference}
        style={{ height: `${shieldSize}px`, width: `${shieldSize}px` }}
        {...getReferenceProps()}
      >
        {/* <Shield
          color={isDisabled ? color.moneyAero70 : getShieldColor(dexLevel)}
          height={shieldSize}
          width={shieldSize}
        /> */}
        <FontAwesomeIcon
          color={isDisabled ? color.moneyAero70 : getShieldColor(dexLevel)}
          icon={faShield}
          style={{ width: `${shieldSize}px`, height: `${shieldSize}px` }}
        />

        <div className={styles.shieldLevel} style={{ fontSize: `${fontSize}px`, opacity: isDisabled ? 0.2 : 1 }}>
          {isDisabled ? '?' : dexLevel}
        </div>
      </div>
      {isOpen && isDisabled && (
        <FloatingPortal>
          <FloatingFocusManager context={context} initialFocus={-1} modal={false}>
            <div
              aria-labelledby={headingId}
              className={styles.popover}
              ref={refs.setFloating}
              style={{ ...floatingStyles, zIndex: 2 }}
              {...getFloatingProps()}
            >
              <FloatingArrow
                context={context}
                fill={color.moneyAero60}
                height={4}
                ref={arrowRef}
                tipRadius={2}
                width={10}
              />

              <h3 className={typography.h4}>{language.en.upgradeTitle}</h3>
              <div
                className={`${typography.bodySmallRegular} ${styles.body}`}
                dangerouslySetInnerHTML={{ __html: language.en.upgradeCopy }}
              />
              <UpgradeProLink />
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default SafetyShield;
