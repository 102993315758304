import { asString } from '../asType';

const [protocol, apiHost] = asString(process.env.NEXT_PUBLIC_SEGMENT_INT_URL, 'NEXT_PUBLIC_SEGMENT_INT_URL').split(
  '://',
);

export const EnvSegment = {
  cfg: {
    writeKey: asString(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY, 'NEXT_PUBLIC_SEGMENT_WRITE_KEY'),
    cdnURL: asString(process.env.NEXT_PUBLIC_SEGMENT_CDN_URL, 'NEXT_PUBLIC_SEGMENT_WRITE_KEY'),
  },
  intergationCfg: {
    apiHost,
    protocol,
  },
};
