import { booleanFeature, FeatValidatorCfg } from './createFeatureFlag';

/**
 * Add your flags here
 * @see ./examples.ts for reference
 */
export const ALL_FEATURE_FLAGS = {
  trading: booleanFeature('Show trading widget'),
  dev_mode: booleanFeature('Enable dev mode'),
} satisfies Record<string, FeatValidatorCfg<unknown>>;

// Utils
export type AllFeatureFlags = typeof ALL_FEATURE_FLAGS;
export type FeatName = keyof AllFeatureFlags;
