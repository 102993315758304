/**
 * A helper function that takes a string as input and capitalizes
 * the first letter of each word in the string.
 *
 * @param input The string that needs to be capitalized.
 * @return The input string with each word's first letter capitalized.
 */
export const capitalizeAllFirstLetters = (input: string): string => {
  return input
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const removeEmptyStrings = <T extends Record<string, any>>(obj: T): Partial<T> => {
  const cleanedObj: Partial<T> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== '') {
      cleanedObj[key as keyof T] = value;
    }
  }
  return cleanedObj;
};
