import { API } from '@/api';
import { fetchJSON } from './fetchJSON';
import { Fetcher as F, merge2, resolveURL } from './lib';

type AuthTokenProp<S extends boolean | undefined> = S extends true
  ? {
      authToken: string | undefined;
    }
  : {
      authToken?: never;
    };

export type FetcherBrowserOptions<T, S extends boolean | undefined> = AuthTokenProp<S> & F.OperationOverrides<T>;

/**
 * @example ```tsx
 * const web3Factory = createFetcherFactory<API.Web3.Paths>({ baseURL: '/api/web3' });
 * const fetchMe = web3Factory('/nft/{address}/{token_id}', 'GET');
 * fetchMe({
 *  pathParams: {
 *    address: '0x1',
 *    token_id: '1',
 *  },
 * });
 * ```
 */
export const createBroswerFetcherFactory = <PathMap extends F.Paths<PathMap>, AT extends boolean = false>(
  svcCfg: {
    baseURL: string;
    headers?: Record<string, string>;
  } & (AT extends true ? { passAuthToken: true } : { passAuthToken?: false }),
) => {
  const baseURL = svcCfg.baseURL.endsWith('/') ? svcCfg.baseURL.slice(0, -1) : svcCfg.baseURL;

  return <P extends keyof PathMap & string, M extends API.Utils.HTTPMethods, MData extends F.MethodData<PathMap, P, M>>(
    path: P,
    method: M & F.GetMethodByPath<PathMap, P>,
  ) => {
    // Helping TS infer the type of the options object
    const fn = async (options: FetcherBrowserOptions<MData, AT> & F.FetchFnArg<MData>) => {
      // TS loses OperationOptions props, so we need to cast it
      const opt = options as F.OperationOptions<MData> & FetcherBrowserOptions<MData, AT>;

      return await fetchJSON({
        method,
        url: resolveURL(baseURL, path, opt),
        headers: merge2(svcCfg.headers, opt.headers),
        body: opt.body,
        authToken: options.authToken,
        response: opt.response ?? fetchJSON.as<API.Utils.Response<MData>>,
        ignoreResponse: opt.ignoreResponse,
        logPrefix: opt.logPrefix,
      });
    };

    const operationName = [createBroswerFetcherFactory.name, '(', method, baseURL, path, ')'].join(' ');
    Object.defineProperty(fn, 'name', { value: operationName });

    return fn;
  };
};
