import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { i18n, Messages } from '@lingui/core';

export const loadCatalog = async (locale: string): Promise<Messages> => {
  const mod = await import(`./locales/${locale}.po`);
  return mod.messages;
};

export const useLinguiInit = (messages: Messages) => {
  const router = useRouter();
  const locale = router.locale ?? router.defaultLocale ?? 'en';
  const isClient = typeof window !== 'undefined';

  if ((isClient && !i18n.locale) || (!isClient && locale !== i18n.locale)) {
    i18n.loadAndActivate({ locale, messages });
  }

  useEffect(() => {
    const localeDidChange = locale !== i18n.locale;
    if (localeDidChange) {
      i18n.loadAndActivate({ locale, messages });
    }
  }, [locale, messages]);

  return i18n;
};
