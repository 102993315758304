import { isDate } from '../isType';

export type QueryParams = Record<string, string | number | boolean | Date | null | undefined>;
export const getURLWithQuery = (url: string, queryParams: QueryParams | undefined): string =>
  queryParams ? appendQueryParams(url, toQueryParams(queryParams)) : url;

export const appendQueryParams = (url: string, queryParams: URLSearchParams | undefined): string => {
  if (!queryParams) {
    return url;
  }
  // Determine the appropriate separator (? or &)
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}${queryParams.toString()}`;
};

const toQueryParams = (params: QueryParams) =>
  new URLSearchParams(
    Object.entries(params)
      .filter(([_key, value]) => value !== null && value !== undefined)
      .map(([key, value]) => [key, String(isDate(value) ? value.toISOString() : value)]),
  );
