import { useCallback, useMemo } from 'react';
import create from 'zustand';
import { useAuthToken } from './useAuth';

type BoolStore<T extends string> = {
  varName: T;
  disable: () => void;
  isEnabled: boolean;
  enable: () => void;
  toggle: () => void;
};

const createBooleanStore = <T extends string>(varName: T) =>
  create<BoolStore<T>>((set) => {
    return {
      varName,
      isEnabled: false,
      disable: () => set({ isEnabled: false }),
      enable: () => set({ isEnabled: true }),
      toggle: () => set(({ isEnabled }) => ({ isEnabled: !isEnabled })),
    };
  });

const useAuthModalZustand = createBooleanStore('authModal');
const { enable: openAuthModal, disable: closeAuthModal, toggle: toggleAuthModal } = useAuthModalZustand.getState();

const useAuthModalState = () => {
  const isAuthModalOpen = useAuthModalZustand((s) => s.isEnabled);
  return useMemo(
    () => ({
      isAuthModalOpen,
      openAuthModal,
      closeAuthModal,
      toggleAuthModal,
    }),
    [isAuthModalOpen],
  );
};

export const useAuthModal = () => {
  const { isLoggedIn } = useAuthToken();
  const am = useAuthModalState();

  const askToLogIn = useCallback(() => {
    if (!isLoggedIn) {
      am.openAuthModal();
    }
    return isLoggedIn;
  }, [isLoggedIn]);

  return {
    ...am,
    askToLogIn,
    isLoggedIn,
  };
};
