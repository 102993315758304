import { API } from '@/api';
import { logApp } from '../logApp';

const log = logApp.create('fetchJSON');
const getError = (response: Response, method = 'fetch') =>
  new Error(`Bad response. ${response.status} ${response.statusText}. Failed to ${method} "${response.url}"`);
const throwError = (resp: Response, method: string) => {
  throw getError(resp, method);
};

const dummyType = <T>(resp: unknown): resp is T => true;

export const fetchJSON = async <T>({
  url,
  body,
  authToken,
  headers,
  initParams,
  method = body ? 'POST' : 'GET',
  onBadResponse = throwError,
  response,
  logPrefix,
  ignoreResponse = false,
}: {
  url: string;
  response: (arg: unknown) => arg is T;
  // (string & { kind?: never }) is to help inverence to suggest GET, POST, etc
  // any other string will be allowed too (e.g. get, pOsT)
  method?: API.Utils.HTTPMethods | (string & { kind?: never });
  onBadResponse?: (response: Response, method: string, json: unknown) => T | Promise<T>;
  authToken?: string;
  headers?: Partial<Record<string, string>>;
  body?: unknown;
  initParams?: Omit<RequestInit, 'body' | 'method' | 'headers'>;
  logPrefix?: string;
  ignoreResponse?: boolean;
}): Promise<T> => {
  logPrefix &&
    log.debug([logPrefix, 'START'], {
      method,
      url,
      hasBody: Boolean(body),
      isLoggedIn: Boolean(authToken),
      ignoreResponse,
      headers,
    });

  const isMultipartFormData = body instanceof FormData;

  const resp = await fetch(url, {
    ...initParams,
    method,
    headers: {
      Authorization: !authToken ? '' : `Bearer ${authToken}`,

      // note that we're sending undefined as the content-type for multipart form data so that the browser can set the Form Boundary automatically (e.g. "multipart/form-data; boundary=----WebKitFormBoundaryXv69Ab9VnAAZqnlG")
      ...(isMultipartFormData ? undefined : { 'content-type': 'application/json' }),

      ...headers,
    },
    body: isMultipartFormData ? body : body && method !== 'GET' ? JSON.stringify(body) : undefined,
    window: null,
  });

  if (!resp.ok) {
    logPrefix &&
      void Promise.resolve()
        .then(() => (resp.bodyUsed || ignoreResponse ? 'N/A' : resp.text()))
        .then((responseText) => {
          log.warn([logPrefix, 'NOT_OK'], {
            method,
            url,
            status: resp.status,
            statusText: resp.statusText,
            responseText,
            body,
          });
        });

    return await onBadResponse(resp, method, undefined);
  }

  if (ignoreResponse) {
    return undefined as T;
  }

  const json = (await resp.json()) as unknown;

  if (!response(json)) {
    log.error([logPrefix ?? 'fetchJSON', 'BAD_TYPE'], {
      method,
      url,
      status: resp.status,
      statusText: resp.statusText,
      resp,
      body,
    });
    return await onBadResponse(resp, method, json);
  }
  return json;
};

fetchJSON.error = getError;
fetchJSON.as = dummyType;
