import { getCookie, setCookie } from 'cookies-next';
import { isRecord } from '../isType';
import { ALL_FEATURE_FLAGS, AllFeatureFlags, FeatName } from './ALL_FEATURE_FLAGS';
import { log } from './log';
import { parseFeatureValueSafe } from './parseFeatureValueSafe';

export const isValidFeatureName = (name: string): name is FeatName => name in ALL_FEATURE_FLAGS;

/**
 * /!\ Important: Don't forget cookie consent
 * to work in prod this cookie name should be allowed as "strictly necessary cookies"
 */
export const FEATURE_FLAG_COOKIE_NAME = 'featureFlags';

/**
 * Can be used in SSR too
 */
export const FeatCookie = {
  delete: (): void => {
    setCookie(FEATURE_FLAG_COOKIE_NAME, '', {
      path: '/',
      maxAge: -1,
    });
  },

  getRawCookie: () => getCookie(FEATURE_FLAG_COOKIE_NAME),

  parse: () => {
    const cookieVal = FeatCookie.getRawCookie();
    if (!cookieVal) {
      return {};
    }
    try {
      const val = JSON.parse(cookieVal);
      if (!isRecord(val)) {
        return {};
      }
      return val;
    } catch (err) {
      log.warnError('Failed to parse feature flag cookie', err);
      return {};
    }
  },

  setFeat: (featureName: string, value: string): void => {
    if (!isValidFeatureName(featureName)) {
      return;
    }
    const currentFlags = FeatCookie.parse();
    try {
      if (value === '') {
        delete currentFlags[featureName];
      } else {
        ALL_FEATURE_FLAGS[featureName].parse(value); // Validate the value
        // Update the cookie
        currentFlags[featureName] = value;
      }
      setCookie(FEATURE_FLAG_COOKIE_NAME, JSON.stringify(currentFlags));
    } catch (err) {
      log.warnError('Failed to set feature flag', err, { featureName, value });
    }
  },

  getRawFeat: (featureName: string) => FeatCookie.parse()[featureName],

  getFeat: <K extends FeatName>(featureName: K) =>
    parseFeatureValueSafe(featureName, FeatCookie.getRawFeat(featureName)) as ReturnType<AllFeatureFlags[K]['parse']>,
};
