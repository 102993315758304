import { FeatCookie } from './featureFlag/FeatCookie';
import { log } from './featureFlag/log';

export const isDevModeEnabled = () => FeatCookie.getFeat('dev_mode');

export const enableDevMode = () => {
  FeatCookie.setFeat('dev_mode', 'true');
  log.info('Developer mode enabled', null);
};

export const disableDevMode = () => {
  FeatCookie.setFeat('dev_mode', '');
  log.info('Developer mode disabled', null);
};
