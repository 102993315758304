import { useEffect } from 'react';
import { ButtonOutline, ButtonPrimary, ButtonTranslucent, Checkbox, Flex, Input, Pill } from 'moralis-ui';
import { useFeatureFlag } from '@/helpers/hooks/useFeatureFlag';
import { disableDevMode } from '@/utils/devMode';
import { ALL_FEATURE_FLAGS, AllFeatureFlags, FeatName } from '@/utils/featureFlag/ALL_FEATURE_FLAGS';
import { FeatCookie } from '@/utils/featureFlag/FeatCookie';
import { logApp } from '@/utils/logApp';

const log = logApp.create('DevModeControlPanel');

const getElmValue = (el: HTMLInputElement) => {
  if (el.type === 'checkbox') {
    return el.checked ? 'true' : '';
  }
  return el.value;
};

export const DevModeControlPanel = ({ onClose }: { onClose: () => void }) => {
  return (
    <form
      onSubmit={(e) => {
        'use client';
        e.preventDefault();
        Object.keys(ALL_FEATURE_FLAGS).forEach((featName) => {
          const input = e.currentTarget.elements.namedItem(featName) as HTMLInputElement;
          const val = getElmValue(input);
          log.debug('submit', { featName, val });
          FeatCookie.setFeat(featName as FeatName, val);
          window.location.reload();
        });
        onClose();
      }}
    >
      <Flex style={{ margin: '0 0 30px 0', flexDirection: 'column', gap: 20 }}>
        {Object.entries(ALL_FEATURE_FLAGS).map(([ff, cfg]) => (
          <div key={ff}>
            <FFLine featName={ff as FeatName} cfg={cfg} />
          </div>
        ))}
      </Flex>

      <div style={{ margin: '30px 0', opacity: 0.5 }}>
        <strong>&nbsp; Cookie</strong>
        <pre style={{ margin: '10px 0' }}>{FeatCookie.getRawCookie()}</pre>
      </div>

      <Flex style={{ padding: '15px 0 0', borderTop: '1px solid rgba(255, 255, 255, .1)' }} justify="space-between">
        <Flex spacing={30}>
          <ButtonPrimary size="sm" type="submit">
            Save changes
          </ButtonPrimary>

          <ButtonOutline
            size="sm"
            onClick={() => {
              FeatCookie.delete();
              onClose();
            }}
          >
            Clear all
          </ButtonOutline>
        </Flex>

        <ButtonTranslucent size="sm" onClick={disableDevMode}>
          Disable dev mode
        </ButtonTranslucent>
      </Flex>
    </form>
  );
};

const FFLine = <F extends FeatName>({ featName, cfg }: { featName: F; cfg: AllFeatureFlags[F] }) => {
  const flagValRaw = FeatCookie.getRawFeat(featName);
  const flagValAsString = String(flagValRaw ?? '');
  const flagValParsed = useFeatureFlag(featName);

  useEffect(() => {
    log.debug(featName, { flagValRaw, flagValParsed, all: FeatCookie.parse() });
  }, [flagValRaw, flagValParsed]);

  if (cfg.inputType === 'checkbox') {
    return (
      <Checkbox
        id={featName}
        name={featName}
        label={<FFLabel featName={featName} desc={cfg.desc} />}
        defaultChecked={Boolean(flagValParsed)}
      />
    );
  }

  return (
    <Flex key={featName} spacing={10} justify="space-between" direction="column">
      <label htmlFor={featName}>
        <FFLabel featName={featName} desc={cfg.desc} />
      </label>
      <Input id={featName} name={featName} type={cfg.inputType} defaultValue={flagValAsString} size="sm" />
    </Flex>
  );
};

const FFLabel = ({ featName, desc }: { featName: string; desc: string }) => (
  <Flex direction="row" spacing={10} align="baseline">
    <Pill size="sm" variant="outline">
      {featName}
    </Pill>
    <span>{desc}</span>
  </Flex>
);
