import { invariant } from './invariant';
import { isRecord, isString } from './isType';

const base64UrlToBase64 = (base64Url: string) => base64Url.replace(/-/g, '+').replace(/_/g, '/');
const decodePart = (part: string) => JSON.parse(atob(base64UrlToBase64(part)));

export const parseMoralisToken = (authToken: string) => {
  const payloadStr = authToken.split('.')[1];
  const payload = decodePart(payloadStr);
  invariant(isRecord(payload), '[parseMoralisToken] Invalid token payload');

  const { address, moneyId, email } = payload;
  invariant(isString(moneyId), '[parseMoralisToken] Invalid moneyId');
  invariant(isString(address), '[parseMoralisToken] Invalid address');

  return {
    moneyId,
    address,
    email: isString(email) ? email : undefined,
  };
};
