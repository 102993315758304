import { type HTMLInputTypeAttribute } from 'react';

export type FeatValidator<V> = (val: string) => V;
export type FeatValidatorCfg<V> = {
  default: V;
  parse: FeatValidator<V>;
  inputType: HTMLInputTypeAttribute;
  desc: string;
};

export const createFeatureFlag = <V>(
  desc: string,
  cfg: {
    default: V;
    parse: FeatValidator<V>;
    inputType?: string;
  },
): FeatValidatorCfg<V> => ({
  default: cfg.default,
  parse: cfg.parse,
  inputType: cfg.inputType ?? (cfg.default === true || cfg.default === false ? 'checkbox' : 'text'),
  desc,
});

export const booleanFeature = (desc: string) =>
  createFeatureFlag(desc, {
    default: false,
    inputType: 'checkbox',
    parse: (val) => {
      switch (val.trim()) {
        case 'true':
        case 'on':
        case '1':
        case 'yes':
        case 'enabled':
          return true;
      }
      return false;
    },
  });

export const stringSetFeature = <T extends string>(desc: string, param: { separator: string; default: Set<T> }) =>
  createFeatureFlag(desc, {
    default: param.default,
    inputType: 'text',
    parse: (val) => new Set(val.trim().split(param.separator).filter(Boolean)),
  });
