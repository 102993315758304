import { ApiServiceClass } from '@/services/ApiServiceClass';
import { useAuthStore } from '@/stores/authStore';

/**
 * @deprecated
 */
export const api = new ApiServiceClass({
  BASE: '/api/',
  HEADERS: async () => {
    const headers = new Headers();
    const { requireEmailToken, authToken } = useAuthStore.getState();
    const bearer = requireEmailToken ?? authToken;
    if (bearer) {
      headers.append('Authorization', `Bearer ${bearer}`);
    }
    return Object.fromEntries(headers);
  },
});
