import create from 'zustand';
import { persist } from 'zustand/middleware';
import { TAuthMethod } from '@/types/auth';

type TAuthStore = {
  authMethod: TAuthMethod | undefined;
  authToken: string | undefined;
  clearAuth: () => void;
  customer: unknown | undefined;
  /**
   * This will only be true if the user does not have an email linked to his account
   */
  requireEmailToken: string | undefined;
  isLoadingSocialLogin: boolean;
  setAuth: (jwt: string, method: TAuthMethod) => void;
  setCustomer: (customer: unknown) => void;
  setRequireEmailToken: (token?: string) => void;
  setIsLoadingSocialLogin: (isLoading: boolean) => void;
};

export const useAuthStore = create(
  persist<TAuthStore>(
    (set) => {
      return {
        authMethod: undefined,
        authToken: undefined,
        customer: undefined,
        requireEmailToken: undefined,
        isLoadingSocialLogin: false,

        setRequireEmailToken: (token?: string) => {
          set(() => {
            return {
              requireEmailToken: token,
            };
          });
        },

        clearAuth: () => {
          set(() => {
            return {
              authMethod: undefined,
              authToken: undefined,
              customer: undefined,
            };
          });
        },

        setAuth: (jwt: string, method: TAuthMethod) => {
          set(() => {
            return {
              authMethod: method,
              authToken: jwt,
            };
          });
        },

        setCustomer: (customer: unknown) => {
          set(() => {
            return {
              customer,
            };
          });
        },
        setIsLoadingSocialLogin: (loading: boolean) => {
          set(() => {
            return {
              isLoadingSocialLogin: loading,
            };
          });
        },
      };
    },
    {
      name: 'mm-auth-storage',
      getStorage: () => {
        return localStorage;
      },
    },
  ),
);
