import { useCallback, useEffect } from 'react';
import useSWR from 'swr';
import { UserCoinFavouritesResponseDto } from '@/services/index';
import { apiBrowser } from '@/utils/api/apiBrowser';
import { fetchHiddenGemsCoins } from '@/utils/api/browser/fetchHiddenGemsCoins';
import { invariant } from '@/utils/invariant';
import { logApp } from '@/utils/logApp';
import { swrHook } from '@/utils/swrHook';
import { useAuthToken } from './useAuth';

const log = logApp.create('useUserCoinFavourites');

const fetchAllFavourites = apiBrowser.user('/user/coinFavourite/all', 'GET');
const useAllFavourites = swrHook.createWithAuth(
  ({ authToken }: { authToken: string | undefined }) => {
    if (!authToken) {
      return null;
    }
    // TODO: (Maxim) fix BE types
    return fetchAllFavourites({ authToken }).then((resp) => resp as unknown as UserCoinFavouritesResponseDto[]);
  },
  {
    key: fetchAllFavourites.name,
  },
);

const saveFavourite = apiBrowser.user('/user/coinFavourite', 'POST');
const deleteFavourite = apiBrowser.user('/user/coinFavourite/{favouriteId}', 'DELETE');

export const useUserCoinFavourites = () => {
  const { isLoggedIn, requireAuthToken } = useAuthToken();

  const {
    data: coinFavourites,
    mutate: mutateCoinFavourites,
    isValidating: isCoinFavouritesValidating,
    isLoading: isCoinFavouritesLoading,
  } = useAllFavourites({});

  const {
    data: coinFavouritesData,
    mutate: mutateTokenData,
    isValidating: isFetching,
    isLoading,
  } = useSWR(
    ['tokenData', coinFavourites],
    !coinFavourites
      ? null
      : async () =>
          await fetchHiddenGemsCoins({
            coinList: coinFavourites.map((coin) => ({
              chainId: coin.chainId,
              coinTokenAddress: coin.tokenAddress,
            })),
          }),
    {
      revalidateOnFocus: false,
    },
  );

  const addCoinFavourite = async (request: { chainId: string; tokenAddress: string }) => {
    invariant(!isCoinFavouritesValidating && !isCoinFavouritesLoading, 'Loading');
    try {
      await saveFavourite({
        body: request,
        authToken: requireAuthToken(),
      });
      mutateCoinFavourites();
    } catch (error) {
      log.error('Error adding coin favourite', error, request);
    }
  };

  const removeCoinFavourite = useCallback(
    async ({ tokenAddress, chainId }: { tokenAddress: string; chainId: string }) => {
      invariant(!isCoinFavouritesValidating && !isCoinFavouritesLoading, 'Loading');
      const fav = coinFavourites?.find((c) => c.chainId === chainId && c.tokenAddress === tokenAddress);
      invariant(fav, 'Favourite not found');

      try {
        await deleteFavourite({
          pathParams: { favouriteId: fav.id },
          authToken: requireAuthToken(),
          ignoreResponse: true,
        });
        await mutateCoinFavourites((data) => data?.filter((coin) => coin.id !== fav.id));
        await mutateTokenData((data) =>
          data?.filter((coin) => coin.chainId_coinTokenAddress !== [chainId, tokenAddress].join('_')),
        );
      } catch (error) {
        log.error('Error removing coin favourite', error, { tokenAddress, chainId });
      }
    },
    [coinFavourites, mutateCoinFavourites, mutateTokenData],
  );

  const resetCoinFavourites = () => {
    mutateCoinFavourites([], false);
    mutateTokenData([], false);
  };

  // Manual refetch function
  const refetchCoinFavourites = useCallback(() => {
    mutateCoinFavourites(); // Re-fetch coin favourites
    mutateTokenData(); // Re-fetch token data
  }, [mutateCoinFavourites, mutateTokenData]);

  useEffect(() => {
    if (!isLoggedIn) {
      resetCoinFavourites();
    }
  }, [isLoggedIn]);

  return {
    coinFavourites,
    coinFavouritesData,
    addCoinFavourite,
    removeCoinFavourite,
    isFetching: isFetching || isLoading || isCoinFavouritesValidating || isCoinFavouritesLoading,
    refetchCoinFavourites,
  };
};
